import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import VoiceRecorder from './VoiceRecorder';
import "./App.css";  // Make sure this line is present

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedRecordings, setExpandedRecordings] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/check-auth`);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Authentication check failed:', error);
      setIsAuthenticated(false);
      navigate('/login');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/logout`, {}, { withCredentials: true });
      setIsAuthenticated(false);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const toggleRecording = (recordingId) => {
    setExpandedRecordings(prev => ({
      ...prev,
      [recordingId]: !prev[recordingId]
    }));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className="app-container">
      <header className="app-header">
        <h1>Voice Notes Recorder</h1>
          <button onClick={handleLogout} className="logout-button">
            <i className="fas fa-sign-out-alt"></i> Logout
          </button>
          <button onClick={() => navigate('/my-profile')} className="profile-button">
            <i className="fas fa-user"></i> My Profile
          </button>
          <button onClick={() => navigate('/dashboard')} className="dashboard-button">
            <i className="fas fa-tachometer-alt"></i> Dashboard
            </button>
      </header>
      <main className="app-main">
        <VoiceRecorder 
          expandedRecordings={expandedRecordings}
          toggleRecording={toggleRecording}
        />
      </main>
    </div>
  );
}

export default App;