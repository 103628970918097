import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
const saveRecording = async (audioURL, setRecordings, showMessage) => {
    if (!audioURL) {
        console.error('No audio to save');
        showMessage('No audio to save', 'error');
        return;
    }

    try {
        const response = await fetch(audioURL);
        const blob = await response.blob();
        let saveResponse;



        
        const uniqueIdentifier = `recording-${uuidv4()}.wav`;
        if (blob.size < 50000000 ) { // 50MB in bytes
            console.log('File size:', blob.size);
            const formData = new FormData();
            formData.append('audio', blob, uniqueIdentifier);

            saveResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/save-recording`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            });

            if (saveResponse.data.success) {
                console.log('Recording saved successfully');
                showMessage('Recording saved successfully', 'success');
                // Fetch updated recordings
                const fetchResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/recordings`, { withCredentials: true });
                setRecordings(fetchResponse.data);
                return true; // Indicate successful save
            } else {
                console.error('Failed to save recording');
                showMessage('Failed to save recording', 'error');
                return false; // Indicate failed save
            }
        } else {
            console.log('File size exceeds 20MB');
            const signedUrlResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/get-signed-url`, {
                filename: uniqueIdentifier,
                contentType: 'audio/wav'
            }, {
                withCredentials: true
            });

            if (signedUrlResponse.data.success) {
                const signedUrl = signedUrlResponse.data.signedUrl;
                const uploadResponse = await fetch(signedUrl, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'audio/wav'
                    },
                    body: blob
                });

                if (uploadResponse.ok) {
                    console.log('File uploaded to S3 successfully');
                    showMessage('File uploaded to S3 successfully', 'success');
                } else {
                    console.error('Failed to upload file to S3');
                    showMessage('Failed to upload file to S3', 'error');
                    return;
                }
            } else {
                console.error('Failed to get signed URL');
                showMessage('Failed to get signed URL', 'error');
                return;
            }
            console.error('File size exceeds 50MB');
            return;
        }

        
    } catch (error) {
        console.error('Error saving recording:', error);
        showMessage('Error saving recording', 'error');
        return false; // Indicate failed save
    }
};

const saveRecording2 = async (audioURL, setRecordings, showMessage) => {
    if (!audioURL) {
        console.error('No audio to save');
        showMessage('No audio to save', 'error');
        return;
    }

    try {
        const response = await fetch(audioURL);
        const blob = await response.blob();
        const formData = new FormData();
        formData.append('audio', blob, 'recording.wav');

        const saveResponse = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/save-recording`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            withCredentials: true
        });

        if (saveResponse.data.success) {
            console.log('Recording saved successfully');
            showMessage('Recording saved successfully', 'success');
            // Fetch updated recordings
            const fetchResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/recordings`, { withCredentials: true });
            setRecordings(fetchResponse.data);
            return true; // Indicate successful save
        } else {
            console.error('Failed to save recording');
            showMessage('Failed to save recording', 'error');
            return false; // Indicate failed save
        }
    } catch (error) {
        console.error('Error saving recording:', error);
        showMessage('Error saving recording', 'error');
        return false; // Indicate failed save
    }
};

export default saveRecording;