import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this import
import './App.css';
import RemoveBackgroundNoise from './app/components/RemoveBackgroundNoise';
import CreateSocialContent from './app/components/createSocialContent';
import FetchRecordings from './app/components/fetchRecordings';
import HandleDelete from './app/components/handleDelete';
import handleNoteSave from './app/components/handleNoteSave';
import HandlePause from './app/components/handlePause';
import HandlePlay from './app/components/handlePlay';
import HandleSeek from './app/components/handleSeek';
import HandleTimeUpdate from './app/components/handleTimeUpdate';
import createSocialContentHandlers from './app/components/handleSocialContent';
import startRecording from './app/components/startRecording';
import saveRecording from './app/components/saveRecording';
import handleTitleSave from './app/components/handleTitleSave';
import stopRecording from './app/components/stopRecording';
import handleRecentSeek from './app/components/handleRecentSeek';
import transcribeAudio from './app/components/transcribeAudio';
import summarizeAudio from './app/components/summarizeAudio';
import useSocketSetup from './app/hooks/useSocketSetup';
import createTweet from './app/components/createTweet';
import createLinkedin from './app/components/createLinkedin';
import axios from 'axios';

function VoiceRecorder() {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState('');
  const [recordings, setRecordings] = useState([]);
  const [editingTitle, setEditingTitle] = useState(null);
  const [editingNote, setEditingNote] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [maxDuration] = useState(7200); // Maximum recording duration in seconds (e.g., 5 minutes)
  const timerRef = useRef(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);
  const [playbackStates, setPlaybackStates] = useState({});
  const [recentRecordingPlayback, setRecentRecordingPlayback] = useState({
    isPlaying: false,
    currentTime: 0,
    duration: 0
  });
  const recentAudioRef = useRef(null);
  const [processing, setProcessing] = useState({});
  const [transcribing, setTranscribing] = useState({});
  const [summarizing, setSummarizing] = useState({});
  const [creatingContent, setCreatingContent] = useState({});
  const [socialContentPlayback, setSocialContentPlayback] = useState({});
  const [message, setMessage] = useState(null);
  const [activeAIMenu, setActiveAIMenu] = useState(null);
  const [activeSocialMenu, setActiveSocialMenu] = useState(null);
  const aiMenuRef = useRef(null);
  const socialMenuRef = useRef(null);
  const [tweetContent, setTweetContent] = useState({});
  const [linkedinContent, setLinkedinContent] = useState({});
  const [freeTrialUsed, setFreeTrialUsed] = useState(false);
  const [freeTrialAttempts, setFreeTrialAttempts] = useState(0);
  const MAX_FREE_TRIAL_ATTEMPTS = 3; // Set this to your desired number of free attempts
  const [profile, setProfile] = useState({ isPro: false }); // Add this state
  const navigate = useNavigate(); // Add this hook
  const [expandedRecordings, setExpandedRecordings] = useState({});
  const [expandedSections, setExpandedSections] = useState({});
  const [audioDevices, setAudioDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const showMessage = (text, type) => {
    setMessage({ text, type });
    setTimeout(() => setMessage(null), 3000); // Hide message after 3 seconds
  };

  const handleDelete = HandleDelete(setRecordings, showMessage);

  const { removeBackgroundNoise, processing: bgNoiseProcessing } = RemoveBackgroundNoise();
  const createSocialContent = CreateSocialContent();
  const fetchRecordings = FetchRecordings();
  const handlePause = HandlePause(setPlaybackStates);
  const handlePlay = HandlePlay(setCurrentAudio, setPlaybackStates);
  const handleSeek = HandleSeek(setPlaybackStates);
  const handleTimeUpdate = HandleTimeUpdate(setPlaybackStates);

  // Use the useSocketSetup hook
  const socket = useSocketSetup(setRecordings, setTranscribing, setProcessing, setSummarizing, setCreatingContent);

  // Create the social content handlers
  const { 
    handleSocialContentPlay, 
    handleSocialContentPause, 
    handleSocialContentTimeUpdate, 
    handleSocialContentSeek 
  } = createSocialContentHandlers(setSocialContentPlayback);

  useEffect(() => {
    const loadRecordings = async () => {
      try {
        const fetchedRecordings = await fetchRecordings();
        setRecordings(fetchedRecordings);
      } catch (error) {
        console.error('Error loading recordings:', error);
      }
    };

    loadRecordings();
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/profile`);
      setProfile(response.data);
      setFreeTrialUsed(response.data.usedFreeTrial);
      setFreeTrialAttempts(response.data.freeTrialAttempts || 0);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const updateFreeTrial = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/update-free-trial`);
      setFreeTrialAttempts(prev => prev + 1);
      if (freeTrialAttempts + 1 >= MAX_FREE_TRIAL_ATTEMPTS) {
        setFreeTrialUsed(true);
      }
    } catch (error) {
      console.error('Error updating free trial:', error);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (aiMenuRef.current && !aiMenuRef.current.contains(event.target)) {
        setActiveAIMenu(null);
      }
      if (socialMenuRef.current && !socialMenuRef.current.contains(event.target)) {
        setActiveSocialMenu(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleTitleEdit = (filename) => {
    setEditingTitle(filename);
  };

  const handleTitleSaveWrapper = (filename, newTitle) => {
    handleTitleSave(filename, newTitle, setRecordings);
    setEditingTitle(null);
  };

  const handleNoteSaveWrapper = async (filename, newNote) => {
    console.log("handleNoteSaveWrapper called with filename:", filename, "and newNote:", newNote);
    if (editingNote === filename) {
      try {
        await handleNoteSave(filename, newNote, setRecordings, setEditingNote);
        showMessage('Note saved successfully', 'success');
      } catch (error) {
        console.error('Error in handleNoteSaveWrapper:', error);
        showMessage(error.message || 'Failed to save note', 'error');
      }
    }
  };

  const handleNoteClick = (filename) => {
    setEditingNote(filename);
  };

  const handleTitleClick = (filename) => {
    setEditingTitle(filename);
  };

  const handleRecentPlay = () => {
    if (recentAudioRef.current) {
      recentAudioRef.current.play();
      setRecentRecordingPlayback(prev => ({ ...prev, isPlaying: true }));
    }
  };

  const handleRecentPause = () => {
    if (recentAudioRef.current) {
      recentAudioRef.current.pause();
      setRecentRecordingPlayback(prev => ({ ...prev, isPlaying: false }));
    }
  };

  const handleRecentTimeUpdate = () => {
    if (recentAudioRef.current && !isNaN(recentAudioRef.current.duration)) {
      setRecentRecordingPlayback(prev => ({
        ...prev,
        currentTime: recentAudioRef.current.currentTime,
        duration: recentAudioRef.current.duration
      }));
    }
  };

  const handleRecentSeekWrapper = (e) => {
    handleRecentSeek(e, recentAudioRef, setRecentRecordingPlayback);
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  useEffect(() => {
    if (audioRef.current) {
      if (currentAudio && playbackStates[currentAudio]?.isPlaying) {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise.then(_ => {
            // Automatic playback started!
          }).catch(error => {
            // Auto-play was prevented
            console.log("Playback prevented. Resetting state:", error);
            setPlaybackStates(prev => ({
              ...prev,
              [currentAudio]: { ...prev[currentAudio], isPlaying: false }
            }));
          });
        }
      } else {
        audioRef.current.pause();
      }
    }
  }, [playbackStates, currentAudio]);

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: selectedDevice ? { exact: selectedDevice } : undefined,
        }
      });

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorder.start();
      setIsRecording(true);

      // Start the timer
      timerRef.current = setInterval(() => {
        setRecordingDuration((prev) => {
          if (prev >= maxDuration) {
            handleStopRecording();
            return prev;
          }
          return prev + 1;
        });
      }, 1000);

    } catch (error) {
      console.error('Error starting recording:', error);
      showMessage('Error starting recording', 'error');
    }
  };

  const handleStopRecording = () => {
    stopRecording(
      setIsRecording,
      setAudioURL,
      mediaRecorderRef,
      audioChunksRef,
      timerRef
    );
  };

  const handleSaveRecording = async () => {
    try {
      await saveRecording(audioURL, setRecordings, showMessage);
      // Clear the audioURL after successful save to hide the playback section
      setAudioURL('');
      // Reset recent recording playback state
      setRecentRecordingPlayback({
        isPlaying: false,
        currentTime: 0,
        duration: 0
      });
    } catch (error) {
      console.error('Error saving recording:', error);
      showMessage('Failed to save to server. Recording is saved locally as backup.', 'error');
    }
  };


  const handleSaveRecordin2 = async () => {
    try {
      await saveRecording(audioURL, setRecordings, showMessage);
      // Clear the audioURL after successful save to hide the playback section
      setAudioURL('');
      // Reset recent recording playback state
      setRecentRecordingPlayback({
        isPlaying: false,
        currentTime: 0,
        duration: 0
      });
    } catch (error) {
      console.error('Error saving recording:', error);
      showMessage('Failed to save to server. Recording is saved locally as backup.', 'error');
    }
  };


  const recoverFromLocalStorage = () => {
    try {
      const localRecordings = JSON.parse(localStorage.getItem('localRecordings') || '[]');
      if (localRecordings.length > 0) {
        // Get the most recent recording
        const latestRecording = localRecordings[localRecordings.length - 1];
        
        // Convert base64 back to blob
        const byteString = atob(latestRecording.blob.split(',')[1]);
        const mimeString = latestRecording.blob.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        const audioUrl = URL.createObjectURL(blob);
        
        setAudioURL(audioUrl);
        setRecentRecordingPlayback({
          isPlaying: false,
          currentTime: 0,
          duration: 0
        });
        showMessage('Recording recovered from local storage', 'success');
      } else {
        showMessage('No local recordings found', 'error');
      }
    } catch (error) {
      console.error('Error recovering from localStorage:', error);
      showMessage('Failed to recover local recording', 'error');
    }
  };

  const toggleAIMenu = (filename, event) => {
    event.stopPropagation();
    setActiveAIMenu(prev => prev === filename ? null : filename);
    setActiveSocialMenu(null); // Close the other menu
  };

  const toggleSocialMenu = (filename, event) => {
    event.stopPropagation();
    setActiveSocialMenu(prev => prev === filename ? null : filename);
    setActiveAIMenu(null); // Close the other menu
  };

  const handleAIOption = async (filename, option, event) => {
    event.stopPropagation();
    if (freeTrialUsed && !profile.isPro) {
      showMessage('Free trial used. Please subscribe under "My Profile" to continue using this feature.', 'error');
      return;
    }
    
    switch(option) {
      case 'transcribe':
        transcribeAudio(filename, setTranscribing);
        break;
      case 'summarize':
        summarizeAudio(filename, setSummarizing);
        break;
      default:
        console.log(`AI Option ${option} not implemented yet`);
    }
    
    if (!profile.isPro) {
      await updateFreeTrial();
    }
    setActiveAIMenu(null);
  };

  const handleSocialOption = async (filename, option, event) => {
    event.stopPropagation();
    if (freeTrialUsed && !profile.isPro) {
      showMessage('Free trial used. Please subscribe under "My Profile" to continue using this feature.', 'error');
      return;
    }
    
    switch(option) {
      case 'createSocialContent':
        createSocialContent(filename, setCreatingContent);
        break;
      case 'createTweet':
        if (recordings.find(r => r.filename === filename).transcription) {
          createTweet(filename, setTweetContent);
        } else {
          showMessage('Transcription is required to create a tweet', 'error');
        }
        break;
      case 'createLinkedin':
        if (recordings.find(r => r.filename === filename).transcription) {
          setCreatingContent(prev => ({ ...prev, [filename]: true }));
          try {
            const response = await createLinkedin(filename);
            if (response.success) {
              setLinkedinContent(prev => ({
                ...prev,
                [filename]: response.linkedinPost
              }));
              showMessage('LinkedIn post created successfully', 'success');
            }
          } catch (error) {
            console.error('Error creating LinkedIn post:', error);
            showMessage('Failed to create LinkedIn post', 'error');
          } finally {
            setCreatingContent(prev => ({ ...prev, [filename]: false }));
          }
        } else {
          showMessage('Transcription is required to create a LinkedIn post', 'error');
        }
        break;
      default:
        console.log(`Social option ${option} not implemented yet`);
    }
    
    if (!profile.isPro) {
      await updateFreeTrial();
    }
    setActiveSocialMenu(null);
  };

  const handleDownload = async (fileUrl, title) => {
    window.open(fileUrl, '_blank');
  };

  const toggleRecording = (recordingId) => {
    setExpandedRecordings(prev => ({
      ...prev,
      [recordingId]: !prev[recordingId]
    }));
  };

  const toggleSection = (recordingId, section) => {
    setExpandedSections(prev => ({
      ...prev,
      [`${recordingId}-${section}`]: !prev[`${recordingId}-${section}`]
    }));
  };

  useEffect(() => {
    const getAudioDevices = async () => {
      try {
        // First check if the device/browser supports enumerateDevices
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
          console.log("Media Devices API not supported");
          return;
        }

        // Request permission first on mobile
        await navigator.mediaDevices.getUserMedia({ audio: true });
        
        const devices = await navigator.mediaDevices.enumerateDevices();
        const audioInputs = devices.filter(device => device.kind === 'audioinput');
        setAudioDevices(audioInputs);

        // Set default device
        if (!selectedDevice && audioInputs.length > 0) {
          setSelectedDevice(audioInputs[0].deviceId);
        }

        // Listen for device changes (e.g., headphones plugged in/out)
        navigator.mediaDevices.addEventListener('devicechange', async () => {
          const updatedDevices = await navigator.mediaDevices.enumerateDevices();
          const updatedAudioInputs = updatedDevices.filter(device => device.kind === 'audioinput');
          setAudioDevices(updatedAudioInputs);
        });

      } catch (error) {
        console.error('Error getting audio devices:', error);
        showMessage('Error accessing audio devices', 'error');
      }
    };

    getAudioDevices();

    // Cleanup listener on component unmount
    return () => {
      if (navigator.mediaDevices) {
        navigator.mediaDevices.removeEventListener('devicechange', getAudioDevices);
      }
    };
  }, []);

  const handleRecentDownload = () => {
    // Create a temporary link element
    const a = document.createElement('a');
    a.href = audioURL;
    a.download = 'recording.wav'; // Default filename
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="voice-recorder-container">
      {freeTrialUsed && !profile.isPro && (
        <div className="free-trial-message">
          <p>You have used up your free trial. Please subscribe to continue using AI and social features.</p>
          <button onClick={() => navigate('/my-profile')} className="subscribe-now-button">
            <i className="fas fa-star"></i> Subscribe Now
          </button>
        </div>
      )}
      <section className="recording-section">
        {audioDevices.length > 1 && ( // Only show selector if multiple devices available
          <div className="microphone-select">
            <select 
              value={selectedDevice || ''} 
              onChange={(e) => setSelectedDevice(e.target.value)}
              className="device-select"
            >
              {audioDevices.map(device => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label || `Microphone ${device.deviceId.slice(0, 5)}...`}
                </option>
              ))}
            </select>
          </div>
        )}
        <button 
          className={`record-button ${isRecording ? "recording" : ""}`}
          onClick={isRecording ? handleStopRecording : handleStartRecording}
          >
          <i className={`fas ${isRecording ? "fa-stop" : "fa-microphone"}`}></i>
          <span className="button-text">
            {isRecording ? "Stop Recording" : "Start Recording"}
          </span>
        </button>
        <button 
          className="recover-button"
          onClick={recoverFromLocalStorage}
          title="Recover last recording from local storage"
        >
          <i className="fas fa-history"></i> Recover Last Recording
        </button>
          {message && (
            <div className={`message-popup ${message.type}`}>
              {message.text}
            </div>
          )}
        {isRecording && (
          <div className="progress-bar-container">
            <div 
              className="progress-bar" 
              style={{width: `${(recordingDuration / maxDuration) * 100}%`}}
            ></div>
            <span className="progress-time">{formatTime(recordingDuration)}</span>
          </div>
        )}
      </section>
      {audioURL && (
        <section className="playback-section">
          <h3>Recent Recording</h3>
          <div className="custom-audio-player">
            <button 
              className={`play-pause-btn ${recentRecordingPlayback.isPlaying ? 'playing' : ''}`}
              onClick={recentRecordingPlayback.isPlaying ? handleRecentPause : handleRecentPlay}
            >
              <i className={`fas ${recentRecordingPlayback.isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
            </button>
            <div className="progress-bar" onClick={handleRecentSeekWrapper}>
              <div 
                className="progress" 
                style={{ width: `${(recentRecordingPlayback.currentTime / recentRecordingPlayback.duration) * 100 || 0}%` }}
              ></div>
            </div>
            <span className="time">
              {formatTime(recentRecordingPlayback.currentTime)} / {formatTime(recentRecordingPlayback.duration)}
            </span>
          </div>
          <audio
            ref={recentAudioRef}
            src={audioURL}
            preload="auto"
            onTimeUpdate={handleRecentTimeUpdate}
            onEnded={() => setRecentRecordingPlayback(prev => ({ ...prev, isPlaying: false }))}
          />
          <div className="recent-recording-buttons">
            <button className="save-button-recording" onClick={handleSaveRecording}>
              <i className="fas fa-save"></i> Save Recording
            </button>
            <button className="download-button-recording" onClick={handleRecentDownload}>
              <i className="fas fa-download"></i>
            </button>
          </div>
        </section>
      )}
      <section className="recordings-grid">
        <h2>Your Recordings</h2>
        <div className="grid-container">
          {recordings.map((recording, index) => (
            <div 
              key={recording._id} 
              className={`grid-item ${expandedRecordings[recording._id] ? 'expanded' : 'collapsed'}`}
            >
              <div 
                className="grid-item-header"
                onClick={() => toggleRecording(recording._id)}
              >
                {editingTitle === recording.filename ? (
                  <input
                    type="text"
                    defaultValue={recording.title}
                    onBlur={(e) => handleTitleSaveWrapper(recording.filename, e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleTitleSaveWrapper(recording.filename, e.target.value);
                      }
                    }}
                    onClick={(e) => e.stopPropagation()}
                    autoFocus
                    className="title-input"
                  />
                ) : (
                  <div className="title-section" onClick={() => toggleRecording(recording._id)}>
                    <h3 
                      className="editable-title"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTitleClick(recording.filename);
                      }}
                    >
                      {recording.title}
                      <i className="fas fa-pencil-alt edit-icon"></i>
                    </h3>
                  </div>
                )}
                <i className={`fas fa-chevron-down toggle-icon ${expandedRecordings[recording._id] ? 'expanded' : ''}`}></i>
              </div>
              <div className="grid-item-content">
                <div className="audio-container">
                  <div className="custom-audio-player">
                    <button 
                      className={`play-pause-btn ${playbackStates[recording.fileUrl]?.isPlaying ? 'playing' : ''}`}
                      onClick={() => playbackStates[recording.fileUrl]?.isPlaying 
                        ? handlePause(recording.fileUrl) 
                        : handlePlay(recording.fileUrl)}
                    >
                      <i className={`fas ${playbackStates[recording.fileUrl]?.isPlaying ? 'fa-pause' : 'fa-play'}`}></i>                 </button>
                    <div className="progress-bar" onClick={(e) => handleSeek(e, recording.fileUrl)}>
                      <div className="progress" style={{ width: `${(playbackStates[recording.fileUrl]?.currentTime / playbackStates[recording.fileUrl]?.duration) * 100 || 0}%` }}></div>
                    </div>
                    <span className="time">
                      {formatTime(playbackStates[recording.fileUrl]?.currentTime || 0)} / 
                      {formatTime(playbackStates[recording.fileUrl]?.duration || 0)}
                    </span>
                  </div>
                </div>
                {editingNote === recording.filename ? (
                  <div className="note-edit">
                    <textarea
                      defaultValue={recording.note || ''}
                      onBlur={(e) => handleNoteSaveWrapper(recording.filename, e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          handleNoteSaveWrapper(recording.filename, e.target.value);
                        }
                      }}
                      autoFocus
                      className="note-input"
                    />
                  </div>
                ) : (
                  <p 
                    className="note-display editable-note"
                    onClick={() => setEditingNote(recording.filename)}
                  >
                    {recording.note || "Click to add a note"}
                  </p>
                )}
                <div className="button-group">
                  <button 
                    className="delete-button stylish-button" 
                    onClick={() => handleDelete(recording.filename)}
                  >
                    <i className="fas fa-trash"></i> Delete
                  </button>
                  <button 
                    className="download-button stylish-button"
                    onClick={() => handleDownload(recording.fileUrl, recording.title)}
                  >
                    <i className="fas fa-download"></i> Download
                  </button>
                  <button 
                    className="noise-removal-button stylish-button"
                    onClick={() => removeBackgroundNoise(recording.filename)}
                    disabled={processing[recording.filename]}
                  >
                    <i className="fas fa-magic"></i> {processing[recording.filename] ? 'Processing...' : 'Remove Noise'}
                  </button>
                  <div className="options-dropdown">
                    <button 
                      className="ai-options-button stylish-button"
                      onClick={(e) => toggleAIMenu(recording.filename, e)}
                    >
                      <i className="fas fa-robot"></i> AI Options
                    </button>
                    {activeAIMenu === recording.filename && (
                      <div ref={aiMenuRef} className="options-menu">
                        <button 
                          onClick={(e) => handleAIOption(recording.filename, 'transcribe', e)}
                          disabled={transcribing[recording.filename]}
                        >
                          <i className="fas fa-file-alt"></i> {transcribing[recording.filename] ? 'Transcribing...' : 'Transcribe'}
                        </button>
                        <button 
                          onClick={(e) => handleAIOption(recording.filename, 'summarize', e)}
                          disabled={summarizing[recording.filename]}
                        >
                          <i className="fas fa-file-alt"></i> {summarizing[recording.filename] ? 'Summarizing...' : 'Summarize'}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="options-dropdown">
                    <button 
                      className="social-options-button stylish-button"
                      onClick={(e) => toggleSocialMenu(recording.filename, e)}
                    >
                      <i className="fas fa-share-alt"></i> Social Options
                    </button>
                    {activeSocialMenu === recording.filename && (
                      <div ref={socialMenuRef} className="options-menu">
                        <button 
                          onClick={(e) => handleSocialOption(recording.filename, 'createSocialContent', e)}
                          disabled={creatingContent[recording.filename]}
                        >
                          <i className="fas fa-share-alt"></i> {creatingContent[recording.filename] ? 'Creating...' : 'Create Social Content'}
                        </button>
                        <button 
                          onClick={(e) => handleSocialOption(recording.filename, 'createTweet', e)}
                          disabled={!recording.transcription}
                        >
                          <i className="fab fa-twitter"></i> Create Tweet
                        </button>
                        <button 
                          onClick={(e) => handleSocialOption(recording.filename, 'createLinkedin', e)}
                          disabled={!recording.transcription || creatingContent[recording.filename]}
                        >
                          <i className="fab fa-linkedin"></i> {creatingContent[recording.filename] ? 'Creating...' : 'Create LinkedIn Post'}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {recording.transcription && (
                  <div className="transcription">
                    {/* <h4>Transcription:</h4> */}
                    {/* <p>{recording.transcription}</p> */}
                  </div>
                )}
                {recording.summary && recording.summary.summary_of_summary && (
                  <div className="accordion-section">
                    <div 
                      className="section-header"
                      onClick={() => toggleSection(recording._id, 'summary')}
                    >        <div className="header-row">
                      <h4>Summary</h4>
                      <i className={`fas fa-chevron-down toggle-icon ${expandedSections[`${recording._id}-summary`] ? 'expanded' : ''}`}></i>
                    </div>
                    </div>
                    {expandedSections[`${recording._id}-summary`] && (
                      <div className="section-content">
                        <p>{recording.summary.summary_of_summary}</p>
                      </div>
                    )}
                  </div>
                )}
                {recording.socialContent && (
                  <div className="accordion-section">
                    <div 
                      className="section-header"
                      onClick={() => toggleSection(recording._id, 'social')}
                    >        <div className="header-row">
                      <h4>Social Content</h4>
                      <i className={`fas fa-chevron-down toggle-icon ${expandedSections[`${recording._id}-social`] ? 'expanded' : ''}`}></i>
                    </div>
                    </div>
                    {expandedSections[`${recording._id}-social`] && (
                      <div className="section-content">
                        <div className="custom-audio-player">
                          <button 
                            className={`play-pause-btn ${socialContentPlayback[recording.socialContent]?.isPlaying ? 'playing' : ''}`}
                            onClick={() => socialContentPlayback[recording.socialContent]?.isPlaying 
                              ? handleSocialContentPause(recording.socialContent) 
                              : handleSocialContentPlay(recording.socialContent)}
                          >
                            
                            <i className={`fas ${socialContentPlayback[recording.socialContent]?.isPlaying ? 'fa-pause' : 'fa-play'}`}></i>
                          </button>
                          <div className="progress-bar" onClick={(e) => handleSocialContentSeek(e, recording.socialContent)}>
                            <div 
                              className="progress" 
                              style={{ width: `${(socialContentPlayback[recording.socialContent]?.currentTime / socialContentPlayback[recording.socialContent]?.duration) * 100 || 0}%` }}
                            ></div>
                          </div>
                          <span className="time">
                            {formatTime(socialContentPlayback[recording.socialContent]?.currentTime || 0)} / 
                            {formatTime(socialContentPlayback[recording.socialContent]?.duration || 0)}
                          </span>
                          <audio
                            id={`social-audio-${recording.socialContent}`}
                            src={recording.socialContent}
                            onTimeUpdate={() => handleSocialContentTimeUpdate(recording.socialContent)}
                            onEnded={() => handleSocialContentPause(recording.socialContent)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {recording.tweetPost && (
                  <div className="accordion-section">
                    <div 
                      className="section-header"
                      onClick={() => toggleSection(recording._id, 'tweet')}
                    >
                              <div className="header-row">
                      <h4><i className="fab fa-twitter"></i> Generated Tweet</h4>
                      <i className={`fas fa-chevron-down toggle-icon ${expandedSections[`${recording._id}-tweet`] ? 'expanded' : ''}`}></i>
                    </div>
                    </div>
                    {expandedSections[`${recording._id}-tweet`] && (
                      <div className="section-content">
                        <div className="social-post-content">
                          <p>{recording.tweetPost}</p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {recording.linkedinPost && (
                  <div className="accordion-section">
                    <div 
                      className="section-header"
                      onClick={() => toggleSection(recording._id, 'linkedin')}
                    >
                      <div className="header-row">
                        <h4><i className="fab fa-linkedin"></i> Generated LinkedIn Post</h4>
                        <i className={`fas fa-chevron-down toggle-icon ${expandedSections[`${recording._id}-linkedin`] ? 'expanded' : ''}`}></i>
                      </div>
                    </div>
                    {expandedSections[`${recording._id}-linkedin`] && (
                      <div className="section-content">
                        <div className="social-post-content">
                          <p>{recording.linkedinPost}</p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </section>
      <audio
        ref={audioRef}
        src={currentAudio}
        id={`audio-${currentAudio}`} // Make sure this ID matches what's used in handleSeek.js
        onTimeUpdate={() => {
          if (currentAudio) handleTimeUpdate(currentAudio);
        }}
        onEnded={() => {
          if (currentAudio) handlePause(currentAudio);
        }}
      />

    </div>
  );
}

export default VoiceRecorder;