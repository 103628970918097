const stopRecording = (setIsRecording, setAudioURL, mediaRecorderRef, audioChunksRef, timerRef) => {
  if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
    mediaRecorderRef.current.stop();
    clearInterval(timerRef.current);
    setIsRecording(false);

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      const audioUrl = URL.createObjectURL(audioBlob);
      setAudioURL(audioUrl);

      // Save to local storage
      const timestamp = new Date().toISOString();
      const recording = {
        blob: audioBlob,
        url: audioUrl,
        timestamp,
        title: `Recording ${timestamp}`,
      };

      // Convert blob to base64 for storage
      const reader = new FileReader();
      reader.readAsDataURL(audioBlob);
      reader.onloadend = () => {
        const base64data = reader.result;
        
        // Get existing recordings or initialize empty array
        const existingRecordings = JSON.parse(localStorage.getItem('localRecordings') || '[]');
        
        // Add new recording
        existingRecordings.push({
          ...recording,
          blob: base64data // Store base64 instead of Blob
        });
        
        // Keep only last 5 recordings to prevent storage issues
        if (existingRecordings.length > 5) {
          existingRecordings.shift(); // Remove oldest recording
        }
        
        // Save to localStorage
        try {
          localStorage.setItem('localRecordings', JSON.stringify(existingRecordings));
        } catch (error) {
          console.error('Error saving to localStorage:', error);
          // If localStorage is full, clear it and try to save current recording
          localStorage.clear();
          localStorage.setItem('localRecordings', JSON.stringify([{
            ...recording,
            blob: base64data
          }]));
        }
      };

      audioChunksRef.current = [];
    };
  }
};

export default stopRecording;